import { useSelector } from 'react-redux';
import { ALERT_MESSAGES, STATUS } from '../../util/constants';
import {
  getApplicationDocumentsState,
  getDocumentFilesState,
  getNavSearchResults,
  getStudentState,
  getUpdateManageReviewerState,
  getCommitteeReviewersFromDBState,
  getActiveReviewerState,
  getNextAppState,
  getOverrideRecommendationState,
  getReportsState,
  getReportAthenaCustomState,
  getExportReportsState,
} from '../redux/selectors';
import { RESET_REPORTSALERTS, RESET_REPORTSFILTERALERTS } from '../../actions-index';

export const useAlertBarStatus = () => {
  let alerts = [];

  const studentState = useSelector((state) => {
    let newState = getStudentState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_APPLICATION
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const applicationDocumentsState = useSelector((state) => {
    let newState = getApplicationDocumentsState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_DOCUMENTS
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const documentFilesState = useSelector((state) => {
    let newState = getDocumentFilesState(state);
    // const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_DOCUMENT
    let message = ALERT_MESSAGES.SUCCESS_UPDATE;
    if (newState.status !== STATUS.SUCCESS) {
        message = newState?.alert?.ALERT_BAR_MESSAGE ?? ALERT_MESSAGES.ERROR_RETRIEVING_DOCUMENT;
    }
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const navbarSearchState = useSelector((state) => {
    let newState = getNavSearchResults(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_SEARCH_RESULTS
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const committeeReviewersState = useSelector((state) => {
    let newState = getCommitteeReviewersFromDBState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_COMMITTEE_REVIEWERS
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const nextAppState = useSelector((state) => {
    let newState = getNextAppState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_RETRIEVING_APPLICATION
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const activeReviewerState = useSelector((state) => {
    let newState = getActiveReviewerState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_UPDATE
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const updateManageReviewerState = useSelector((state) => {
    let newState = getUpdateManageReviewerState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_UPDATE
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const overrideState = useSelector((state) => {
    let newState = getOverrideRecommendationState(state);
    const message = newState.status === STATUS.SUCCESS ? ALERT_MESSAGES.SUCCESS_UPDATE : ALERT_MESSAGES.ERROR_OVERRIDING_APPLICATION
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }
    return newState;
  });
  const reportsState = useSelector((state) => {
    let newState = getReportsState(state);
    const reportDataError = newState.status !== STATUS.SUCCESS ? newState?.alert?.MESSAGE : undefined;
    const status = reportDataError ? STATUS.ERROR : undefined; // undefined here is the only way to remove with below filter
    const MESSAGE = reportDataError;

    const finalState = {
      status,
      alert: {
        MESSAGE,
      },
      type: RESET_REPORTSALERTS
    };
    
    return finalState;
  });
  const reportsFiltersState = useSelector((state) => {
    let newState = getReportsState(state);
    const reportCustomFilterError = newState.data?.filterOptionsCustomStatus !== STATUS.SUCCESS ? newState?.data?.customFiltersErrorAlert?.MESSAGE : undefined;
    const status = reportCustomFilterError ? STATUS.ERROR : undefined; // undefined here is the only way to remove with below filter
    const MESSAGE = reportCustomFilterError;

    const finalState = {
      status,
      alert: {
        MESSAGE,
      },
      type: RESET_REPORTSFILTERALERTS
    };
    
    return finalState;
  });
  const reportAthenaCustomState = useSelector((state) => {
    let newState = getReportAthenaCustomState(state) ?? {};
    const message = newState?.status === STATUS.SUCCESS ? undefined : newState?.alert?.MESSAGE;
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }

    return newState;
  });
  const exportReportState = useSelector(state => {
    let newState = getExportReportsState(state) ?? {};
    const message = newState?.status === STATUS.SUCCESS ? undefined : newState?.alert?.MESSAGE;
    if (newState.status && newState?.alert?.MESSAGE) {
      newState.alert = {
        ...newState.alert,
        MESSAGE: message,
      };
    }

    return newState;
  });

  // useEffect(() => {
    const handleAlerts = (state) => {
      if (state?.status && state?.alert?.MESSAGE) {
        if (!alerts.some((alert) => JSON.stringify(alert) === JSON.stringify(state))) {
          alerts.push(state);
        }
      } else if (!state?.status) {
        alerts = alerts.filter((alert) => alert.type !== state.type);
      }
    };

    handleAlerts(studentState);
    handleAlerts(applicationDocumentsState);
    handleAlerts(documentFilesState);
    handleAlerts(navbarSearchState);
    handleAlerts(nextAppState); // undefined type?? TODO This should have a type. There's something weird going on in next app reducer (initial state is an array, but transform in actions is object)
    handleAlerts(updateManageReviewerState);
    handleAlerts(committeeReviewersState);
    handleAlerts(activeReviewerState);
    handleAlerts(overrideState);
    handleAlerts(reportsState);
    handleAlerts(reportsFiltersState);
    handleAlerts(reportAthenaCustomState);
    handleAlerts(exportReportState);

  return alerts;
};
