import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckIcon from '@material-ui/icons/Check';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SchoolIcon from '@material-ui/icons/School';
import { Mui } from "@osu/react-ui";


const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true";
const OSU_NAV_ID = "osu-navbar";
const NAVBAR_WRAPPER_ID = "admissions-header";
const MAIN_ID = "page-content";
const ACCESSIBLE_REFLOW_HEIGHT = `399px`;
const TOC_WIDTH = `15rem`;

const COMMITTEE_TRAINING = {
    id: "training",
    name: "Training",
};

const COMMITTEE_COLOR_MAP = {
  "athlete": "#00CCFF",
  "domestic-freshman": "#40E0D0",
  "international-freshman": "#CCCCFF",
  "domestic-transfer": "#99CC66",
  "international-transfer": "#FFCCFF",
  "military": "#FFCCCC",
  "non-traditional": "#FFCC00",
  "ccp-metro-hs": "#FFB36B",
  "ccp-academy": "#FF9999",
  "ccp-academy-regional": "#a832a2",
};

const RESIDENCY_MAP = {
  "A": "Special Visa in State",
  "B": "Special Visa Conditional",
  "C": "Conditional Resident",
  "I": "International",
  "N": "Non-Ohio Resident",
  "Q": "C-3 Instant",
  "R": "Ohio Resident",
}
const AUTH_TYPES = {
  ADMIN: "ADMIN",
  READER: "READER", // todo: remove reader
  REVIEWER: "REVIEWER",
  UNAUTHORIZED: "UNAUTHORIZED",
  ADMIN_REVIEWER: "ADMIN_REVIEWER"
}

const STATUSES = {
   RESUMELATER: "RESUMELATER",
   ASSIGNED: "ASSIGNED",
   NOTIFY: "NOTIFY",
   CANCELLED: "CANCELLED",
}

const REVIEW_NOTES_MAP = {
  COMPETITIVE: {
    KEY: "COMPETITIVE",
    LABEL: "Competitive Notes",
    DESCRIPTION: "",
  },
  NONCOMPETITIVE: {
    KEY: "NONCOMPETITIVE",
    LABEL: "Noncompetitive Notes",
    DESCRIPTION: "",
  },
  DOCUMENT: { KEY: "DOCUMENT", LABEL: "Document Notes", DESCRIPTION: "" },
  EVALUATION: { KEY: "EVALUATION", LABEL: "Evaluation Notes", DESCRIPTION: "" },
  OTHERCONCERNS: {
    KEY: "OTHERCONCERNS",
    LABEL: "Other Notes/Concerns",
    DESCRIPTION: "",
  },
};

const APPLICATION_TYPES = {
  TRAINING: 'TRAINING',
}

const RECOMMENDATION_HISTORY_UPDATES = {
  ASSIGN: 'ASSIGN',
  UNASSIGN: 'UNASSIGN'
}

const STATUS = {
    ASSIGNED: "ASSIGNED",
    RESUMELATER: "RESUMELATER",
    LOADING: "LOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    CANCEL: "CANCEL",
    CANCELLED: "CANCELLED",
    CURRENT: 'CURRENT',
    ACTIVE: 'ACTIVE',
    INACTIVE: "INACTIVE",
    UPDATE_SUCCESSFUL: 'UPDATE_SUCCESSFUL',
    INPROGRESS: 'INPROGRESS',
    ADMINREVIEW: 'ADMINREVIEW',
    PENDINGAPPROVAL: 'PENDINGAPPROVAL',
    COMPLETE: 'COMPLETE',
    SUBMITTED: 'SUBMITTED',
    CLEAR: 'CLEAR',
    REFETCH: 'REFETCH',
    success: "success",
}

const STATUS_BOOLEAN = {
  YES: 'Yes',
  NO: 'No',
}

const SEVERITY_LEVELS = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
}

const ERROR_CONSTS = {
  UI: {
    GENERIC: {
      EMPTY_ALERT: {
        MESSAGE: '',
        SEVERITY: null,
      },
      SELECT_REVIEWER_EMPTY: {
        MESSAGE: 'Please select an option before submitting.',
        SEVERITY: SEVERITY_LEVELS.WARNING,
      },
      SELECT_COMPLETEDBYDATE_EMPTY: {
        MESSAGE: 'Please select a valid date before submitting.',
        SEVERITY: SEVERITY_LEVELS.WARNING,
      },
      NOTIFY_EMPTY: {
        MESSAGE: 'Please choose at least one option before submitting.',
        SEVERITY: SEVERITY_LEVELS.WARNING,
      },
    },
  },
};

const ALERT_MESSAGES = {
  ERROR_RETRIEVING_APPLICATION:
    'Error retrieving application.',
  ERROR_RETRIEVING_REPORT:
    'Error retrieving report.',
  ERROR_RETRIEVING_DOCUMENTS:
    'Error retrieving documents.',
  ERROR_RETRIEVING_DOCUMENT:
    'Error retrieving document.',
  ERROR_RETRIEVING_SEARCH_RESULTS:
    'Applicant does not exist or error retrieving search results.',
  ERROR_RETRIEVING_COMMITTEES:
    'Error retrieving committees.',
  ERROR_RETRIEVING_COMMITTEE_REVIEWERS:
    'Error retrieving committee reviewers.',
  ERROR_OVERRIDING_APPLICATION:
    'Error overriding application.',
  ERROR_UPDATE:
    'Error updating records.',
  SUCCESS_UPDATE:
    'Updated successfully.',
  ERROR_UPDATING_USER_INPUTS:
    'Error updating the selected fields.',
  SUCCESS_UPDATING_USER_INPUTS:
    'Success updating the selected fields.',
  SUCCESS_OVERRIDING_APPLICATION:
    'Recommendation successfully submitted.',
  };

const RECOMMENDATIONS = {
    ADMIT_WITH_RESTRICTION: "Admit with Restriction", // Only need to validate against this for now (AFAIK)
};

const NOTES_KEYS = {
    RESTRICTIONS: "restrictions",
};

const DOCUMENTTYPE_TO_KEYWORDNAMES = {
    "ADM Application": [],
    "ADM High School Document": ["High School Document Type"],
    "ADM Correspondence": ["Correspondence Type"],
    "ADM University Document": ["University Document Type"],
};

const SEARCH = {
  VIEW: 'View Only',
  APPLICANT: 'Applicant',
  APPLICATION: 'Application',
  REVIEWER: 'Reviewer',
  SEARCH: 'Search',
  TRAINING: 'Training',
  TRAINING_APPLICATION: 'Training Application'
}

const TOOLTIPS = (type) => {
  const iconStyles = {
    margin: '.1rem 0 0 .3rem'
  }

  switch(type){
    case "ASSIGNED":
      return (
        <Mui.Tooltip title={"This application has been manually assigned."} placement="right">
          <AssignmentLateIcon fontSize="small" style={{ color: '#ffbe45', ...iconStyles }} />
        </Mui.Tooltip>
        )
    case "PRIORITY":
      return (
        <Mui.Tooltip title={"This application has been marked as a priority."} placement="right">
          <AssignmentLateIcon fontSize="small" color='primary' style={{...iconStyles}} />
        </Mui.Tooltip>
        )
    case "RESUMELATER":
      return (
        <Mui.Tooltip title={"This application has been saved for later."} placement="right">
          <BookmarkIcon fontSize="small" style={{ color: '#639ed6', ...iconStyles }} />
        </Mui.Tooltip>
        )
    case "NOTIFY":
      return (
        <Mui.Tooltip title={"This application has been flagged for review."} placement="right">
          <ReportProblemIcon fontSize="small" style={{ color: '#a8224a', ...iconStyles }} />
        </Mui.Tooltip>
        )
    case "NOTIFY_CLEARED":
      return (
        <Mui.Tooltip title={"Returned by Admin."} placement="right">
          <CheckIcon fontSize="small" style={{ color: '#639ed6', ...iconStyles }} />
        </Mui.Tooltip>
        )
    case "TRAINING":
    return (
      <Mui.Tooltip title={"This application has been cloned for training."} placement="right">
        <SchoolIcon fontSize="small" style={{ color: '#ffbe45', ...iconStyles }} />
      </Mui.Tooltip>
      )
    case "PASTDUE":
      return (
        <Mui.Tooltip title={"This application is past due."} placement="right">
          <AccessAlarmIcon fontSize="small" style={{ color: '#f72d05', ...iconStyles }} />
        </Mui.Tooltip>
        )
    default:
      return null
    }
  }

export {
  ACCESSIBLE_REFLOW_HEIGHT,
  ALERT_MESSAGES,
  APPLICATION_TYPES,
  AUTH_TYPES,
  COMMITTEE_COLOR_MAP,
  COMMITTEE_TRAINING,
  DOCUMENTTYPE_TO_KEYWORDNAMES,
  ERROR_CONSTS,
  FORCED_AUTHENTICATION,
  MAIN_ID,
  NAVBAR_WRAPPER_ID,
  NOTES_KEYS,
  OSU_NAV_ID,
  RECOMMENDATIONS,
  RECOMMENDATION_HISTORY_UPDATES,
  RESIDENCY_MAP,
  REVIEW_NOTES_MAP,
  SEARCH,
  SEVERITY_LEVELS,
  STATUS,
  STATUSES,
  STATUS_BOOLEAN,
  TOC_WIDTH,
  TOOLTIPS,
};
